import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType } from '../../../config/columnRules';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { VALID_DIVIDEND_TYPES } from '../../../config/constants';

export class BitvavoDividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const bitvavoOptions = {
      dateField: 'Date',
      timeField: 'Time',
      dateTimeFormat: 'Europe/Amsterdam',
      typeField: 'Type',
      ...options
    };
    super(csvContent, selectedBroker, bitvavoOptions);
  }

  filterData(data) {
    return data.filter(row => {
      const type = row[this.options.typeField].toLowerCase();
      return type === 'staking';
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateField] + ' ' + row[this.options.timeField], this.options.dateTimeFormat);
      const cryptoCurrency = row['Currency'];
      const amount = Math.abs(parseFloat(row['Amount']));
      
      return {
        broker: this.selectedBroker,
        name: cryptoCurrency,
        type: this.getValidSecurityType(cryptoCurrency),
        search: cryptoCurrency,
        exchange: '',
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'dividend-type': VALID_DIVIDEND_TYPES[2], // 'Staking' - Bitvavo only has staking rewards
        'dividend-amount': amount.toString().replace('.', ','),
        'dividend-amount-currency': this.getValidCurrency(cryptoCurrency),
        'dividend-amount-exchange-rate': '',
        'dividend-amount-exchange-rate-currency': '',
        'dividend-tax': '',
        'dividend-tax-currency': '',
        'dividend-tax-exchange-rate': '',
        'dividend-tax-exchange-rate-currency': ''
      };
    });
  }
}
