import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType } from '../../../config/columnRules';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';

export class BUXTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const buxOptions = {
      dateTimeField: 'Transaction Time (CET)',
      dateTimeFormat: 'CET',
      categoryField: 'Transaction Category',
      typeField: 'Transaction Type',
      ...options
    };
    super(csvContent, selectedBroker, buxOptions);
  }

  filterData(data) {
    return data.filter(row => {
      const category = (row[this.options.categoryField] || '').toLowerCase();
      const type = (row[this.options.typeField] || '').toLowerCase();
      
      return category === 'trades' && 
             (type === 'buy trade' || type === 'sell trade');
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateTimeField]);
      const securityType = determineSecurityType(row['Asset Name'], row['Asset Id']);
      const isBuy = (row[this.options.typeField] || '').toLowerCase().startsWith('buy');
      const amount = Math.abs(this.parseAmount(row['Trade Quantity'] || '0'));
      const price = Math.abs(this.parseAmount(row['Trade Price'] || '0'));
      const exchangeRate = row['Exchange Rate'];
      const formattedExchangeRate = exchangeRate !== '1' ? formatAmount(this.parseAmount(exchangeRate || '0')) : '';
      const assetId = row['Asset Id'] || '';
      const assetName = row['Asset Name'] || '';
      const assetCurrency = row['Asset Currency'] || '';

      // Validate asset ID
      const validateAssetId = this.rules.search?.validation || ((value, context) => true);
      const isValidAssetId = validateAssetId(assetId, { type: securityType });

      return {
        broker: this.selectedBroker,
        name: assetName,
        type: this.getValidSecurityType(assetName, assetId),
        search: isValidAssetId ? assetId : '',
        exchange: '',
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'transaction-action': this.getValidTransactionAction(isBuy),
        'transaction-amount': formatAmount(amount),
        'transaction-price': formatAmount(price),
        'transaction-price-currency': this.getValidCurrency(assetCurrency),
        'transaction-price-exchange-rate': formattedExchangeRate,
        'transaction-price-exchange-rate-currency': formattedExchangeRate ? this.getValidCurrency('EUR') : '',
        'transaction-costs': '',
        'transaction-costs-currency': '',
        'transaction-costs-exchange-rate': '',
        'transaction-costs-exchange-rate-currency': '',
        'transaction-tax': '',
        'transaction-tax-currency': '',
        'transaction-tax-exchange-rate': '',
        'transaction-tax-exchange-rate-currency': ''
      };
    });
  }
}
