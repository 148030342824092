import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';

export class BUXExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const buxOptions = {
      dateTimeField: 'Transaction Time (CET)',
      categoryField: 'Transaction Category',
      typeField: 'Transaction Type',
      ...options
    };
    super(csvContent, selectedBroker, buxOptions);
  }

  filterData(data) {
    return data.filter(row => {
      const category = (row[this.options.categoryField] || '').toLowerCase();
      const type = (row[this.options.typeField] || '').toLowerCase();

      return (
        // Include fees and interest charges
        category === 'fees' ||
        (category === 'interest' && type.includes('charge')) ||
        // Include promotional items (they are in 'others' category)
        (category === 'others' && type === 'promotional') ||
        // Include any other expense-related categories
        type.includes('fee') || 
        type.includes('commission')
      );
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateTimeField]);
      const amount = Math.abs(this.parseAmount(row['Transaction Amount'] || '0'));
      const baseCurrency = row['Transaction Currency'] || '';
      const exchangeRate = row['Exchange Rate'];
      const formattedExchangeRate = exchangeRate !== '1' ? formatAmount(this.parseAmount(exchangeRate || '0')) : '';
      const quoteCurrency = this.exchangesCache?.getQuoteCurrency(baseCurrency) || '';

      return {
        'broker': this.selectedBroker,
        'date': formatDate(dateTime),
        'time': formatTime(dateTime),
        'description': this.determineExpenseType(row[this.options.typeField]),
        'expense-amount': formatAmount(amount),
        'expense-amount-currency': this.getValidCurrency(baseCurrency),
        'expense-amount-exchange-rate': formattedExchangeRate,
        'expense-amount-exchange-rate-currency': formattedExchangeRate ? this.getValidCurrency(quoteCurrency) : ''
      };
    });
  }

  determineExpenseType(type) {
    type = (type || '').toLowerCase();

    if (type === 'promotional') return 'Promotional';
    if (type.includes('commission')) return 'Commission';
    if (type.includes('fee')) return 'Fee';
    return 'Other Expense';
  }
}
