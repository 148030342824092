import { BaseConverter } from '../../../core/BaseConverter';
import { determineSecurityType } from '../../../config/columnRules';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { mapExchange } from '../../../config/exchangeMapping';

export class DEGIROTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const degiroOptions = {
      dateField: 'Datum',
      timeField: 'Tijd',
      ...options
    };
    super(csvContent, selectedBroker, degiroOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row || !row[this.options.dateField]) {
        return false;
      }

      // Check if it's a transaction by verifying if Aantal (quantity) is present and not zero
      const quantity = this.parseAmount(row.Aantal || '0');
      return !isNaN(quantity) && quantity !== 0;
    });
  }

  transformData(data) {
    return data.map(row => {
      // Parse quantity and price using the base parseAmount function
      const quantity = this.parseAmount(row.Aantal || '0');
      const price = this.parseAmount(row.Koers || '0');
      
      // Get currency from the column after Aantal
      const rawRow = Object.values(row);
      const aantalIndex = Object.keys(row).indexOf('Aantal');
      const priceCurrency = this.getValidCurrency(rawRow[aantalIndex + 1] || '');
      
      if (isNaN(quantity) || isNaN(price)) {
        return null;
      }

      const transactionCosts = this.parseAmount(row.Transactiekosten || '0');
      const exchangeRate = this.parseAmount(row.Wisselkoers || '0');

      return {
        broker: this.selectedBroker,
        name: row.Product || '',
        type: determineSecurityType(row.Product || '', row.ISIN || ''),
        search: row.ISIN || '',
        exchange: mapExchange(row.Beurs || ''),
        date: formatDate(row[this.options.dateField]),
        time: formatTime(row[this.options.timeField]),
        'transaction-action': this.getValidTransactionAction(quantity > 0),
        'transaction-amount': formatAmount(Math.abs(quantity)),
        'transaction-price': formatAmount(price),
        'transaction-price-currency': priceCurrency,
        'transaction-price-exchange-rate': exchangeRate ? formatAmount(exchangeRate) : '',
        'transaction-price-exchange-rate-currency': exchangeRate ? this.getValidCurrency('EUR') : '',
        'transaction-costs': transactionCosts ? formatAmount(Math.abs(transactionCosts)) : '',
        'transaction-costs-currency': transactionCosts ? this.getValidCurrency('EUR') : '',
        'transaction-costs-exchange-rate': '',
        'transaction-costs-exchange-rate-currency': '',
        'transaction-tax': '',
        'transaction-tax-currency': '',
        'transaction-tax-exchange-rate': '',
        'transaction-tax-exchange-rate-currency': ''
      };
    }).filter(row => row !== null);
  }
}
