import { BaseConverter } from './BaseConverter';

/**
 * Converter that captures rows that were not processed by other converters
 * This helps users understand which data was not included in the conversion
 */
export class SkippedRowsConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, processedRows) {
    super(csvContent, selectedBroker, { type: 'skippedRows' });
    this.processedRows = processedRows;
  }

  /**
   * Override validation to always return true since we want to show all skipped rows
   */
  validateInput() {
    return true;
  }

  /**
   * Override validation to always return true for all rows
   */
  validateRow() {
    return true;
  }

  /**
   * Filter to only include rows that weren't processed by other converters
   */
  filterData(data) {
    if (!Array.isArray(data) || !Array.isArray(this.processedRows)) {
      console.log('Invalid data or processedRows array');
      return [];
    }

    // Create a Set of processed row identifiers for efficient lookup
    const processedRowIds = new Set(
      this.processedRows.map(row => this.createRowIdentifier(row))
    );

    console.log(`Total rows: ${data.length}, Processed rows: ${processedRowIds.size}`);

    return data.filter(row => {
      const rowId = this.createRowIdentifier(row);
      return !processedRowIds.has(rowId);
    });
  }

  /**
   * Create a unique identifier for a row based on its contents
   * This helps us match rows across different converters
   */
  createRowIdentifier(row) {
    // Create an identifier using key fields that should uniquely identify a row
    // Adjust these fields based on your data structure
    const identifyingFields = [
      row['Transaction Time (CET)'] || '',
      row['Transaction Type'] || '',
      row['Transaction Amount'] || '',
      row['Transaction Currency'] || '',
      row['Asset Name'] || '',
      row['Asset Id'] || ''
    ];
    return identifyingFields.join('|');
  }

  /**
   * Transform data by keeping original format but making it more readable
   */
  transformData(data) {
    return data.map(row => {
      // Create a more readable version of the row
      const transformed = {};
      
      // Add a reason why this row was skipped (if we can determine it)
      transformed['Skip Reason'] = this.determineSkipReason(row);
      
      // Include all original fields
      Object.entries(row).forEach(([key, value]) => {
        transformed[key] = value || '';
      });

      return transformed;
    });
  }

  /**
   * Try to determine why a row was skipped
   */
  determineSkipReason(row) {
    const type = (row['Transaction Type'] || '').toLowerCase();
    const category = (row['Transaction Category'] || '').toLowerCase();
    
    // Add logic to determine why the row was skipped
    if (!type && !category) {
      return 'Missing transaction type and category';
    }
    
    if (type.includes('fee') || type.includes('interest')) {
      return 'Fee/Interest transaction (handled by expense converter)';
    }
    
    if (category.includes('corporate') && !type.includes('dividend')) {
      return 'Corporate action (not dividend)';
    }
    
    return 'Did not match any conversion criteria';
  }

  /**
   * Override the sort method to use Transaction Time (CET) if available
   */
  sortData(data) {
    if (!data || !Array.isArray(data)) return data;

    return data.sort((a, b) => {
      const dateA = a['Transaction Time (CET)'] || '';
      const dateB = b['Transaction Time (CET)'] || '';
      
      if (!dateA && !dateB) return 0;
      if (!dateA) return 1;
      if (!dateB) return -1;
      
      return new Date(dateA) - new Date(dateB);
    });
  }

  /**
   * Format output to show all fields in a readable format
   */
  formatOutput(data) {
    if (!data || data.length === 0) {
      return '';
    }

    // Get all unique headers from the data, excluding Skip Reason as it will be added first
    const headers = ['Skip Reason', ...Array.from(
      new Set(data.flatMap(row => Object.keys(row)))
    ).filter(header => header !== 'Skip Reason')];

    return this.formatTSV(headers, data.map(row => 
      headers.map(header => row[header] || '')
    ));
  }
}
