import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';

export class BitvavoExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const bitvavoOptions = {
      dateField: 'Date',
      timeField: 'Time',
      dateTimeFormat: 'Europe/Amsterdam',
      typeField: 'Type',
      ...options
    };
    super(csvContent, selectedBroker, bitvavoOptions);
    this.knownCryptos = new Set();
  }

  // Helper method to identify known cryptocurrencies from buy/sell transactions
  findKnownCryptos(data) {
    data.forEach(row => {
      const type = (row[this.options.typeField] || '').toLowerCase();
      if (['buy', 'sell'].includes(type)) {
        this.knownCryptos.add(row['Currency']);
      }
    });
  }

  filterData(data) {
    // First identify all cryptocurrencies that have been traded
    this.findKnownCryptos(data);

    // Return empty array since all fees are already handled in transaction costs
    return [];
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(
        `${row[this.options.dateField]} ${row[this.options.timeField]}`,
        this.options.dateTimeFormat
      );
      const fee = parseFloat(row['Fee amount']) || 0;
      const feeCurrency = row['Fee currency'] || 'EUR';
      const type = (row[this.options.typeField] || '').toLowerCase();
      
      return {
        broker: this.selectedBroker,
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        description: `${type.charAt(0).toUpperCase() + type.slice(1)} fee`,
        'expense-amount': formatAmount(fee),
        'expense-amount-currency': this.getValidCurrency(feeCurrency),
        'expense-amount-exchange-rate': '',
        'expense-amount-exchange-rate-currency': ''
      };
    });
  }
}
