import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { mapExchange } from '../../../config/exchangeMapping';
import { VALID_SECURITY_TYPES } from '../../../config/constants';

export class AbnAmroTransactionConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Datum',
      nameField: 'Naam',
      amountField: 'Aantal/Bedrag',
      priceField: 'Koers',
      typeField: 'Type',
      orderTypeField: 'Order type',
      netValueField: 'Netto waarde',
      type: 'transactions',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField] || !row[this.options.nameField]) {
        return false;
      }

      const orderType = (row[this.options.orderTypeField] || '').toLowerCase();
      
      // Only include regular buy/sell transactions
      return orderType === 'aankoop' || orderType === 'verkoop';
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = Math.abs(this.parseAmount(row[this.options.amountField] || '0'));
      const price = Math.abs(this.parseAmount(row[this.options.priceField] || '0'));
      const netValue = Math.abs(this.parseAmount(row[this.options.netValueField] || '0'));
      
      if (isNaN(amount) || amount === 0) {
        return null;
      }

      const orderType = (row[this.options.orderTypeField] || '').toLowerCase();
      const isBuy = orderType === 'aankoop' || 
                   (orderType === 'ca' && amount > 0 && price > 0);
      
      return {
        broker: this.selectedBroker,
        name: row[this.options.nameField] || '',
        type: VALID_SECURITY_TYPES[0],
        search: '',
        exchange: '',
        date: formatDate(row[this.options.dateField]),
        time: formatTime(row[this.options.timeField] || '00:00'),
        'transaction-action': this.getValidTransactionAction(isBuy),
        'transaction-amount': formatAmount(amount),
        'transaction-price': formatAmount(price || netValue / amount),
        'transaction-price-currency': this.getValidCurrency(row.Currency || 'EUR'),
        'transaction-price-exchange-rate': '',
        'transaction-price-exchange-rate-currency': '',
        'transaction-costs': '',
        'transaction-costs-currency': '',
        'transaction-costs-exchange-rate': '',
        'transaction-costs-exchange-rate-currency': '',
        'transaction-tax': '',
        'transaction-tax-currency': '',
        'transaction-tax-exchange-rate': '',
        'transaction-tax-exchange-rate-currency': ''
      };
    }).filter(row => row !== null);
  }
}
