import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { mapExchange } from '../../../config/exchangeMapping';
import { COLUMN_RULES } from '../../../config/columnRules';

export class DEGIROBookingConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const degiroOptions = {
      dateField: 'Datum',
      timeField: 'Tijd',
      ...options
    };
    super(csvContent, selectedBroker, degiroOptions);
  }

  filterData(data) {
    // Skip header row and filter booking entries
    return data.slice(1).filter(row => {
      return row && 
             row.Datum && // Date
             row.Product && // Name
             row.ISIN && // ISIN
             row.Beurs && // Exchange
             row.Omschrijving && // Description
             !row.Omschrijving.toLowerCase().includes('dividend') && // Exclude dividends
             (row.Omschrijving.toLowerCase().includes('booking') || 
              row.Omschrijving.toLowerCase().includes('boeken')) &&
             row.Totaal && // Amount
             !isNaN(parseFloat(String(row.Totaal).replace(',', '.')));
    });
  }

  transformData(data) {
    return data.map(row => {
      // Extract and validate amount
      const amountStr = String(row.Totaal || '0').replace(',', '.');
      const amount = parseFloat(amountStr);
      
      if (isNaN(amount)) {
        return null;
      }

      const output = {
        broker: this.selectedBroker,
        name: row.Product || '',
        type: 'Booking',
        search: row.ISIN || '',
        exchange: mapExchange(row.Beurs || ''),
        date: formatDate(row.Datum),
        time: formatTime(row.Tijd),
        'booking-action': this.getValidBookingAction(row.Omschrijving),
        'booking-amount': formatAmount(Math.abs(amount)),
        'booking-amount-currency': this.getValidCurrency(row._4 || 'EUR')
      };

      // Validate output against column rules
      const rules = { ...COLUMN_RULES.common, ...COLUMN_RULES.booking };
      for (const [field, rule] of Object.entries(rules)) {
        if (rule.validation && !rule.validation(output[field], output)) {
          return null;
        }
      }

      return output;
    }).filter(row => row !== null);
  }
}
