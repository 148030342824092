// Valid transaction actions
export const VALID_TRANSACTION_ACTIONS = ['Buy', 'Sell'];

// Valid security types
export const VALID_SECURITY_TYPES = ['Stock market', 'Crypto', 'Commodity'];

// Valid booking actions
export const VALID_BOOKING_ACTIONS = ['Deposit', 'Withdrawal'];

// Valid commodity types
export const VALID_COMMODITIES = ['Gold', 'Silver', 'Platinum', 'Palladium'];

// Valid dividend types
export const VALID_DIVIDEND_TYPES = ['Cash', 'Stock', 'Staking', 'DRIP'];

// API endpoints
export const API_URLS = {
  CURRENCIES: 'https://app.portfoliodividendtracker.com/api/import/data/currencies.csv',
  BROKERS: 'https://app.portfoliodividendtracker.com/api/import/data/brokers.csv',
  EXCHANGES: 'https://app.portfoliodividendtracker.com/api/import/data/exchanges.csv'
};
