import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { parseDateTimeString } from '../../../utils/dateTimeParser';
import { determineSecurityType } from '../../../config/columnRules';
import { VALID_DIVIDEND_TYPES } from '../../../config/constants';

export class BUXDividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const buxOptions = {
      dateTimeField: 'Transaction Time (CET)',
      categoryField: 'Transaction Category',
      typeField: 'Transaction Type',
      ...options
    };
    super(csvContent, selectedBroker, buxOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      const category = (row[this.options.categoryField] || '').toLowerCase();
      const type = (row[this.options.typeField] || '').toLowerCase();

      return (
        (category.includes('corporate') || category.includes('dividend')) &&
        (type.includes('dividend') || type.includes('capital') || type.includes('return'))
      );
    });
  }

  transformData(data) {
    return data.map(row => {
      const dateTime = parseDateTimeString(row[this.options.dateTimeField]);
      const amount = Math.abs(this.parseAmount(row['Transaction Amount'] || '0'));
      const exchangeRate = row['Exchange Rate'];
      const formattedExchangeRate = exchangeRate !== '1' ? formatAmount(this.parseAmount(exchangeRate || '0')) : '';
      const assetName = row['Asset Name'] || '';
      const assetId = row['Asset Id'] || '';
      const securityType = this.getValidSecurityType(assetName, assetId);
      const baseCurrency = row['Asset Currency'] || '';
      const quoteCurrency = formattedExchangeRate ? 'EUR' : '';
      const transactionType = (row[this.options.typeField] || '').toLowerCase();

      // Determine dividend type based on transaction type
      let dividendType;
      if (transactionType.includes('stock')) {
        dividendType = VALID_DIVIDEND_TYPES[1]; // 'Stock'
      } else if (transactionType.includes('staking')) {
        dividendType = VALID_DIVIDEND_TYPES[2]; // 'Staking'
      } else {
        dividendType = VALID_DIVIDEND_TYPES[0]; // Default to 'Cash' for other types
      }

      return {
        broker: this.selectedBroker,
        name: assetName,
        type: this.getValidSecurityType(assetName, assetId),
        search: assetId,
        exchange: '',
        date: formatDate(dateTime),
        time: formatTime(dateTime),
        'dividend-type': dividendType,
        'dividend-amount': formatAmount(amount),
        'dividend-amount-currency': this.getValidCurrency(baseCurrency),
        'dividend-amount-exchange-rate': formattedExchangeRate,
        'dividend-amount-exchange-rate-currency': quoteCurrency ? this.getValidCurrency(quoteCurrency) : ''
      };
    });
  }

  determineDividendType(type) {
    type = (type || '').toLowerCase();
    
    if (type.includes('capital return')) return 'Capital Return';
    if (type.includes('dividend')) {
      if (type.includes('special')) return 'Special Dividend';
      return 'Regular Dividend';
    }
    return 'Other';
  }
}
