import { useState, useEffect } from 'react';
import './App.scss';
// DEGIRO converters
import { DEGIROTransactionConverter } from './brokers/degiro/converters/DEGIROTransactionConverter';
import { DEGIRODividendConverter } from './brokers/degiro/converters/DEGIRODividendConverter';
import { DEGIROBookingConverter } from './brokers/degiro/converters/DEGIROBookingConverter';
import { DEGIROExpenseConverter } from './brokers/degiro/converters/DEGIROExpenseConverter';
// BUX converters
import { BUXTransactionConverter } from './brokers/bux/converters/BUXTransactionConverter';
import { BUXDividendConverter } from './brokers/bux/converters/BUXDividendConverter';
import { BUXBookingConverter } from './brokers/bux/converters/BUXBookingConverter';
import { BUXExpenseConverter } from './brokers/bux/converters/BUXExpenseConverter';
// Bitvavo converters
import { BitvavoTransactionConverter } from './brokers/bitvavo/converters/BitvavoTransactionConverter';
import { BitvavoDividendConverter } from './brokers/bitvavo/converters/BitvavoDividendConverter';
import { BitvavoBookingConverter } from './brokers/bitvavo/converters/BitvavoBookingConverter';
import { BitvavoExpenseConverter } from './brokers/bitvavo/converters/BitvavoExpenseConverter';
// ABN AMRO converters
import { AbnAmroTransactionConverter } from './brokers/abn-amro/converters/AbnAmroTransactionConverter';
import { AbnAmroDividendConverter } from './brokers/abn-amro/converters/AbnAmroDividendConverter';
import { AbnAmroBookingConverter } from './brokers/abn-amro/converters/AbnAmroBookingConverter';
import { AbnAmroExpenseConverter } from './brokers/abn-amro/converters/AbnAmroExpenseConverter';
// Validators
import { isValidBroker } from './utils/validators';
import { SkippedRowsConverter } from './core/SkippedRowsConverter';
import Papa from 'papaparse';
import CsvPreprocessorFactory from './core/csvPreprocessor/CsvPreprocessorFactory.js';

function App() {
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [converting, setConverting] = useState(false);
  const [error, setError] = useState(null);
  const [toast, setToast] = useState({ show: false, message: '', type: 'error' });
  const [convertedData, setConvertedData] = useState({
    transactions: null,
    dividends: null,
    bookings: null,
    expenses: null,
    skippedRows: null
  });
  const [activeTab, setActiveTab] = useState('transactions');
  const [showModal, setShowModal] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [selectedBroker, setSelectedBroker] = useState(() => {
    const savedBroker = localStorage.getItem('selectedBroker');
    return savedBroker || 'DEGIRO';  
  });

  useEffect(() => {
    localStorage.setItem('selectedBroker', selectedBroker);
  }, [selectedBroker]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
      setError(null);
      setConvertedData({
        transactions: null,
        dividends: null,
        bookings: null,
        expenses: null,
        skippedRows: null
      });
      setCopySuccess(false);
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setError(null);
      setConvertedData({
        transactions: null,
        dividends: null,
        bookings: null,
        expenses: null,
        skippedRows: null
      });
      setCopySuccess(false);
    }
  };

  const showToast = (message, type = 'error') => {
    setToast({ show: true, message, type });
    setTimeout(() => setToast({ show: false, message: '', type: 'error' }), 5000);
  };

  const handleConvert = async () => {
    if (!file) {
      showToast('No file selected');
      return;
    }

    if (file.type !== 'text/csv' && !file.name.toLowerCase().endsWith('.csv')) {
      showToast('Please select a valid CSV file');
      return;
    }

    if (!selectedBroker) {
      showToast('Please select a broker');
      return;
    }

    // Validate broker
    try {
      const isValid = await isValidBroker(selectedBroker);
      if (!isValid) {
        throw new Error('Invalid broker selected');
      }
    } catch (error) {
      return;
    }
    
    setConverting(true);
    setError(null);
    setShowModal(false);
    setConvertedData({
      transactions: null,
      dividends: null,
      bookings: null,
      expenses: null,
      skippedRows: null
    });
    setCopySuccess(false);
    
    try {
      const fileContent = await file.text();
      
      // Preprocess the CSV content if a preprocessor exists for this broker
      let processedContent = fileContent;
      const preprocessor = CsvPreprocessorFactory.getPreprocessor(selectedBroker);
      if (preprocessor) {
        try {
          processedContent = preprocessor.preprocess(fileContent);
          console.log('CSV preprocessed successfully');
        } catch (err) {
          console.warn('CSV preprocessing failed:', err);
          // Continue with original content if preprocessing fails
        }
      }
      
      const parsedData = Papa.parse(processedContent, { header: true, skipEmptyLines: true }).data;
      console.log('Total rows in CSV:', parsedData.length);

      const converterMap = {
        DEGIRO: {
          transactions: DEGIROTransactionConverter,
          dividends: DEGIRODividendConverter,
          bookings: DEGIROBookingConverter,
          expenses: DEGIROExpenseConverter
        },
        BUX: {
          transactions: BUXTransactionConverter,
          dividends: BUXDividendConverter,
          bookings: BUXBookingConverter,
          expenses: BUXExpenseConverter
        },
        BITVAVO: {
          transactions: BitvavoTransactionConverter,
          dividends: BitvavoDividendConverter,
          bookings: BitvavoBookingConverter,
          expenses: BitvavoExpenseConverter
        },
        'ABN Amro': {
          transactions: AbnAmroTransactionConverter,
          dividends: AbnAmroDividendConverter,
          bookings: AbnAmroBookingConverter,
          expenses: AbnAmroExpenseConverter
        }
      };

      // Get the normalized broker key (case-insensitive)
      const brokerKey = Object.keys(converterMap).find(
        key => key.toLowerCase() === selectedBroker.toLowerCase()
      );

      // Validate that we have converters for the selected broker
      if (!brokerKey) {
        throw new Error(`No converters found for broker: ${selectedBroker}`);
      }

      const results = [];
      const newData = {};
      const processedRows = [];

      // Try each converter
      for (const [type, ConverterClass] of Object.entries(converterMap[brokerKey])) {
        try {
          // All brokers now use converter classes
          const converterInstance = new ConverterClass(fileContent, selectedBroker, { type });
          const result = await converterInstance.convert();

          if (result) {
            results.push({ type, data: result });
            newData[type] = result;
            
            // Store processed rows for skipped rows tracking
            const filteredData = converterInstance.filterData(parsedData);
            if (Array.isArray(filteredData)) {
              processedRows.push(...filteredData);
              console.log(`Added ${filteredData.length} rows from ${type} converter`);
            }
          } else {
            newData[type] = null;
          }
        } catch (err) {
          console.error(`Failed to convert ${type}:`, err);
          newData[type] = null;
        }
      }

      // Process skipped rows
      try {
        console.log('Total processed rows before skipped check:', processedRows.length);
        const skippedRowsConverter = new SkippedRowsConverter(fileContent, selectedBroker, processedRows);
        const skippedResult = await skippedRowsConverter.convert();
        console.log('Skipped rows result:', skippedResult ? skippedResult.split('\n').length - 1 : 0, 'rows');
        
        if (skippedResult && skippedResult.split('\n').length > 1) {
          newData.skippedRows = skippedResult;
          console.log('Added skipped rows to newData');
        }
      } catch (err) {
        console.error('Failed to process skipped rows:', err);
        newData.skippedRows = null;
      }

      if (Object.values(newData).every(v => v === null)) {
        throw new Error('No data could be converted from the file');
      }

      setConvertedData(newData);
      
      // Set active tab to first available conversion
      const firstAvailableTab = Object.keys(newData).find(key => newData[key]);
      if (firstAvailableTab) {
        setActiveTab(firstAvailableTab);
      }

      // Show modal with results
      setShowModal(true);
    } catch (error) {
      console.error('Conversion error:', error);
      setError(error.message);
      showToast(error.message, 'error');
    } finally {
      setConverting(false);
    }
  };

  const handleCopyToClipboard = async () => {
    try {
      const dataWithoutHeaders = convertedData[activeTab].split('\n').slice(1).join('\n');
      await navigator.clipboard.writeText(dataWithoutHeaders);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
      setToast({ show: true, message: 'Failed to copy to clipboard', type: 'error' });
      setTimeout(() => setToast({ show: false, message: '', type: '' }), 3000);
    }
  };

  const handleDownload = () => {
    // Split into rows and ensure proper CSV formatting
    const rows = convertedData[activeTab].split('\n');
    const formattedRows = rows.map(row => {
      // Split by tabs and join with semicolons
      return row.split('\t').join(';');
    }).join('\n');

    const extension = '.csv';
    const blob = new Blob([formattedRows], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `converted-${activeTab}${extension}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleEscapeKey = (event) => {
    if (event.key === 'Escape') {
      setShowModal(false);
    }
  };

  const handleBrokerChange = (e) => {
    setSelectedBroker(e.target.value);
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', handleEscapeKey);
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [showModal]);

  return (
    <div className="min-h-screen bg-gray-50 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="relative px-4 pt-5 pb-4 bg-white mx-8 md:mx-0 shadow-xl rounded-lg sm:p-6">
          <div className="max-w-md mx-auto">
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-gray-900">Transaction Converter</h1>
              <p className="mt-2 text-sm text-gray-600">Convert your broker exports to our standardized PDT format</p>
            </div>

            <div className="mt-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Select Broker
              </label>
              <div className="relative">
                <select
                  className="block w-full pl-3 pr-10 py-2.5 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-white shadow-sm appearance-none cursor-pointer"
                  value={selectedBroker}
                  onChange={handleBrokerChange}
                >
                  <option value="DEGIRO">DEGIRO</option>
                  <option value="BUX">BUX</option>
                  <option value="Bitvavo">Bitvavo</option>
                  <option value="ABN Amro">ABN Amro</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <div
                className={`relative border-2 border-dashed rounded-lg p-6 ${
                  isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'
                }`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="space-y-1 text-center">
                  <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48">
                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <div className="text-sm text-gray-600">
                    <label htmlFor="file-upload" className="relative cursor-pointer">
                      <span>{file ? file.name : 'Drop CSV file here or'}</span>
                      <span className="text-blue-600 hover:text-blue-700"> browse</span>
                      <input
                        id="file-upload"
                        type="file"
                        className="sr-only"
                        accept=".csv"
                        onChange={handleFileSelect}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">CSV files only</p>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <button
                onClick={handleConvert}
                disabled={converting || !file}
                className={`w-full inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                  converting || !file
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                }`}
              >
                {converting ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Converting...
                  </>
                ) : (
                  'Convert'
                )}
              </button>
            </div>

            {error && (
              <div className="mt-4 rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-red-800">{error}</p>
                  </div>
                </div>
              </div>
            )}

            {toast.show && (
              <div className={`fixed bottom-4 right-4 rounded-md p-4 shadow-lg ${
                toast.type === 'error' ? 'bg-red-500' : 'bg-green-500'
              } text-white`}>
                <p className="text-sm font-medium">{toast.message}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div 
          className="fixed z-10 inset-0 overflow-y-auto"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-title"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <h3 className="text-2xl font-semibold text-gray-900 flex items-center justify-center">
                    Conversion Successful
                    <svg className="h-6 w-6 ml-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </h3>
                </div>

                <div className="mt-6">
                  <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {Object.entries(convertedData).map(([type, data]) =>
                        data ? (
                          <button
                            key={type}
                            onClick={() => setActiveTab(type)}
                            className={`${
                              activeTab === type
                                ? 'border-blue-600 text-blue-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none`}
                          >
                            {type === 'skippedRows' ? 'Skipped Rows' : type.charAt(0).toUpperCase() + type.slice(1)}
                            <span className={`ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium ${
                              activeTab === type ? 'bg-blue-100 text-blue-600' : 'bg-gray-100 text-gray-600'
                            }`}>
                              {data.split('\n').length - 1}
                            </span>
                          </button>
                        ) : null
                      )}
                    </nav>
                  </div>

                  <div className="mt-6">
                    <div className="bg-gray-50 rounded-lg overflow-hidden border border-gray-200">
                      {activeTab === 'skippedRows' ? (
                        <div className="overflow-x-auto max-h-96">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-100 sticky top-0 z-10">
                              <tr>
                                {convertedData[activeTab].split('\n')[0].split('\t').map((header, index) => (
                                  <th
                                    key={index}
                                    scope="col"
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap bg-gray-100"
                                  >
                                    {header}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {convertedData[activeTab]
                                .split('\n')
                                .slice(1) // Skip header row
                                .filter(row => row.trim()) // Skip empty rows
                                .map((row, rowIndex) => (
                                  <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                    {row.split('\t').map((cell, cellIndex) => (
                                      <td
                                        key={cellIndex}
                                        className={`px-6 py-4 text-sm ${
                                          cellIndex === 0 ? 'text-red-600 font-medium' : 'text-gray-900'
                                        } whitespace-nowrap`}
                                      >
                                        {cell}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <pre className="p-4 overflow-x-auto max-h-96 text-sm text-gray-800">
                          {convertedData[activeTab]}
                        </pre>
                      )}
                    </div>
                  </div>

                  <div className="mt-6 flex justify-end space-x-3">
                    {activeTab !== 'skippedRows' && (
                      <button
                        onClick={handleCopyToClipboard}
                        className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                          copySuccess ? 'bg-green-600' : 'bg-blue-600 hover:bg-blue-700'
                        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                      >
                        {copySuccess ? (
                          <>
                            <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                            Copied!
                          </>
                        ) : (
                          <>
                            <svg className="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                            </svg>
                            Copy to clipboard
                          </>
                        )}
                      </button>
                    )}
                    <button
                      onClick={handleDownload}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Download
                    </button>
                    <button
                      onClick={() => setShowModal(false)}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Close
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
