/**
 * Sorts an array of objects by date and time fields
 * @param {Array} data Array of objects containing date and time fields
 * @param {string} dateField Name of the date field (default: 'date')
 * @param {string} timeField Name of the time field (default: 'time')
 * @returns {Array} Sorted array
 */
export const sortByDateTime = (data, dateField = 'date', timeField = 'time') => {
  return data.sort((a, b) => {
    // Handle cases where date or time fields are missing
    if (!a[dateField] || !b[dateField]) {
      return !a[dateField] ? 1 : -1; // Push rows with missing dates to the end
    }

    // Compare dates first
    const dateA = a[dateField].split('-').reverse().join('-'); // Convert DD-MM-YYYY to YYYY-MM-DD
    const dateB = b[dateField].split('-').reverse().join('-');
    const dateCompare = dateA.localeCompare(dateB);
    
    // If dates are equal, compare times
    if (dateCompare === 0) {
      // Handle cases where time field is missing
      if (!a[timeField] || !b[timeField]) {
        return !a[timeField] ? 1 : -1; // Push rows with missing times to the end
      }
      return a[timeField].localeCompare(b[timeField]);
    }
    
    return dateCompare;
  });
};
