import { API_URLS } from '../config/constants';
import Papa from 'papaparse';

// Cache for API responses
let currenciesCache = null;
let exchangesCache = null;
let brokersCache = null;

// Default valid brokers (must match the broker cache exactly)
const DEFAULT_BROKERS = ['DEGIRO', 'BUX', 'Bitvavo', 'ABN Amro'];

/**
 * Fetches and parses CSV data from a URL
 * @param {string} url - URL to fetch CSV from
 * @returns {Promise<Array>} Parsed CSV data
 */
const fetchCSV = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const csvText = await response.text();
    const result = Papa.parse(csvText, { header: true, skipEmptyLines: true });
    return result.data;
  } catch (error) {
    console.error(`Error fetching CSV from ${url}:`, error);
    return [];
  }
};

/**
 * Gets the list of valid currencies
 * @returns {Promise<string[]>} List of currency codes
 */
export const getValidCurrencies = async () => {
  if (currenciesCache) {
    return currenciesCache;
  }

  const currencies = await fetchCSV(API_URLS.CURRENCIES);
  currenciesCache = currencies.map(row => row.code);
  return currenciesCache;
};

/**
 * Gets the list of valid exchanges
 * @returns {Promise<string[]>} List of exchange codes
 */
export const getValidExchanges = async () => {
  if (exchangesCache) {
    return exchangesCache;
  }

  const exchanges = await fetchCSV(API_URLS.EXCHANGES);
  exchangesCache = exchanges.map(row => row.code);
  return exchangesCache;
};

/**
 * Gets the list of valid brokers
 * @returns {Promise<string[]>} List of broker codes
 */
export const getValidBrokers = async () => {
  try {
    const response = await fetch('https://api.codeium.com/cascade/brokers');
    if (!response.ok) {
      throw new Error('Failed to fetch brokers');
    }
    const data = await response.json();
    return data.brokers || [];
  } catch (error) {
    return ['Unknown'];
  }
};

/**
 * Validates a currency code
 * @param {string} currency - Currency code to validate
 * @returns {boolean} True if valid
 */
export const validateCurrency = (currency) => {
  if (!currency) return false;
  // Basic currency format validation: 3 uppercase letters
  return /^[A-Z]{3}$/.test(currency);
};

/**
 * Validates a currency code
 * @param {string} currency - Currency code to validate
 * @returns {Promise<boolean>} True if valid
 */
export const isValidCurrency = async (currency) => {
  if (!currency) return false;
  if (!validateCurrency(currency)) return false;
  const validCurrencies = await getValidCurrencies();
  return validCurrencies.includes(currency.toUpperCase());
};

/**
 * Validates an exchange code
 * @param {string} exchange - Exchange code to validate
 * @returns {Promise<boolean>} True if valid
 */
export const isValidExchange = async (exchange) => {
  if (!exchange) return false;
  if (exchange === 'UNKNOWN' || exchange === 'Funds') return true;
  const validExchanges = await getValidExchanges();
  return validExchanges.includes(exchange.toUpperCase());
};

/**
 * Validates a broker code
 * @param {string} broker - Broker code to validate
 * @returns {Promise<boolean>} True if valid
 */
export const isValidBroker = async (broker) => {
  if (!broker) return false;
  
  // Check default brokers first with case-insensitive comparison
  if (DEFAULT_BROKERS.some(b => b.toLowerCase() === broker.toLowerCase())) {
    return true;
  }
  
  try {
    const validBrokers = await getValidBrokers();
    return validBrokers.some(b => b.toLowerCase() === broker.toLowerCase());
  } catch (error) {
    return false;
  }
};
