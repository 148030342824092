/**
 * Formats a number to a standardized string format with comma as decimal separator
 * @param {number|string} amount - The amount to format
 * @returns {string} Formatted amount string
 */
export const formatAmount = (amount) => {
  // Handle null, undefined, empty string
  if (amount === null || amount === undefined || amount === '') return '';
  
  // Convert to number if string
  const num = typeof amount === 'string' ? parseFloat(amount.replace(',', '.')) : amount;
  
  // Return '0,00' only for actual zero values
  if (num === 0) return '0';
  
  // Format with 2 decimal places and replace dot with comma
  return num.toFixed(2).replace('.', ',');
};

/**
 * Parses a string amount to a number
 * @param {string} amount - The amount string to parse
 * @returns {number} Parsed amount
 */
export const parseAmount = (amount) => {
  if (!amount) return 0;
  return parseFloat(amount.replace(',', '.'));
};

/**
 * Formats a date to DD-MM-YYYY format
 * @param {string|Date} date - Date to format
 * @returns {string} Formatted date string
 */
export const formatDate = (date) => {
  if (!date) return '';
  
  let d;
  
  if (date instanceof Date) {
    d = date;
  } else if (typeof date === 'string') {
    // Handle YYYY-MM-DD HH:mm:ss format (BUX format)
    if (date.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
      const [datePart] = date.split(' ');
      const [year, month, day] = datePart.split('-').map(num => parseInt(num, 10));
      d = new Date(year, month - 1, day);
    }
    // Handle DD/MM/YYYY format
    else if (date.includes('/')) {
      const [day, month, year] = date.split('/').map(num => parseInt(num, 10));
      d = new Date(year, month - 1, day);
    }
    // Handle DD-MM-YYYY format
    else if (date.includes('-')) {
      const parts = date.split('-');
      // Check if it's already in YYYY-MM-DD format
      if (parts[0].length === 4) {
        const [year, month, day] = parts.map(num => parseInt(num, 10));
        d = new Date(year, month - 1, day);
      } else {
        const [day, month, year] = parts.map(num => parseInt(num, 10));
        d = new Date(year, month - 1, day);
      }
    }
    // Try standard date parsing as fallback
    else {
      d = new Date(date);
    }
  } else {
    return '';
  }
  
  // Check if date is valid
  if (isNaN(d.getTime())) {
    return '';
  }
  
  const day = d.getDate().toString().padStart(2, '0');
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const year = d.getFullYear();
  
  return `${day}-${month}-${year}`;
};

/**
 * Formats time to HH:MM:SS format
 * @param {string|Date|null|undefined} time - Time to format
 * @param {string} defaultTime - Default time to use if no time provided (default: '00:00:00')
 * @returns {string} Formatted time string
 */
export const formatTime = (time, defaultTime = '00:00:00') => {
  if (!time) return defaultTime;
  
  let d;
  
  if (time instanceof Date) {
    d = time;
  } else if (typeof time === 'string') {
    // If the time is already in HH:MM:SS format, return it
    if (time.match(/^\d{2}:\d{2}:\d{2}$/)) {
      return time;
    }
    // Handle YYYY-MM-DD HH:mm:ss format (BUX format)
    if (time.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
      const [, timePart] = time.split(' ');
      return timePart;
    }
    // Handle HH:MM or HH:MM:SS format
    else if (time.includes(':')) {
      const parts = time.split(':').map(num => parseInt(num, 10));
      d = new Date();
      d.setHours(parts[0]);
      d.setMinutes(parts[1]);
      d.setSeconds(parts[2] || 0);
    }
    // Try standard date parsing as fallback
    else {
      d = new Date(time);
    }
  } else {
    return defaultTime;
  }
  
  // Check if date is valid
  if (isNaN(d.getTime())) {
    return defaultTime;
  }
  
  const hours = d.getHours().toString().padStart(2, '0');
  const minutes = d.getMinutes().toString().padStart(2, '0');
  const seconds = d.getSeconds().toString().padStart(2, '0');
  
  return `${hours}:${minutes}:${seconds}`;
};
