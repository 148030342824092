import { VALID_TRANSACTION_ACTIONS, VALID_SECURITY_TYPES, VALID_COMMODITIES, VALID_DIVIDEND_TYPES } from './constants';
import { isValidCurrency as validateCurrency } from '../utils/validators';

// Common validation functions
const isValidDate = (value) => {
  if (!value) return false;
  // Validate DD-MM-YYYY format
  const pattern = /^\d{1,2}-\d{1,2}-\d{4}$/;
  return pattern.test(value);
};

const isValidTime = (value) => {
  if (!value) return false;
  // Validate HH:MM format
  const pattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  return pattern.test(value);
};

const isValidAmount = (value) => {
  if (!value) return true; // Empty values are allowed
  // Allow numbers with optional decimal places and comma as decimal separator
  const pattern = /^\d+(?:,\d{1,2})?$/;
  return pattern.test(value);
};

const isValidIsin = (value) => {
  if (!value) return true;
  // ISIN format: 2 letters followed by 10 alphanumeric characters
  const pattern = /^[A-Z]{2}[A-Z0-9]{10}$/;
  return pattern.test(value);
};

const isValidAssetId = (value, type = '') => {
  if (!value) return true;

  // For Crypto assets, allow common crypto symbols (1-5 uppercase letters)
  if (type === 'Crypto') {
    return /^[A-Z]{1,5}$/.test(value);
  }

  // For Commodities, check against valid commodity names
  if (type === 'Commodity') {
    return VALID_COMMODITIES.includes(value);
  }

  // For stocks and other types, allow ISIN or stock symbols
  return isValidIsin(value) || /^[A-Z]{1,6}$/.test(value);
};

const isValidCurrency = (value) => {
  // Currency format: 3 letters
  const pattern = /^[A-Z]{3}$/;
  return pattern.test(value);
};

const parseAmount = (value) => {
  if (!value) return 0;
  return parseFloat(value.replace(',', '.'));
};

const isValidBroker = (value) => {
  // Add your logic to validate broker name
  return !!value;
};

export const COLUMN_RULES = {
  // Common rules for all output files
  common: {
    broker: {
      required: true,
      description: 'Broker name',
      validation: isValidBroker
    },
    date: {
      required: true,
      description: 'Date in DD-MM-YYYY format',
      validation: isValidDate
    },
    time: {
      required: true,
      description: 'Time in HH:MM format',
      validation: isValidTime
    },
    currency: {
      required: false,
      description: 'Currency code',
      validation: validateCurrency
    },
    search: {
      required: true,
      description: 'ISIN, stock symbol, crypto symbol, or commodity name',
      validation: (value, context) => isValidAssetId(value, context?.type)
    }
  },

  // Transaction specific rules
  transaction: {
    name: {
      required: true,
      description: 'Security name'
    },
    type: {
      required: true,
      description: 'Security type',
      validation: value => VALID_SECURITY_TYPES.includes(value)
    },
    exchange: {
      required: true,
      description: 'Exchange'
    },
    'transaction-action': {
      required: true,
      description: 'Action (Buy/Sell)',
      validation: value => VALID_TRANSACTION_ACTIONS.includes(value)
    },
    'transaction-amount': {
      required: true,
      description: 'Amount',
      validation: isValidAmount
    },
    'transaction-price': {
      required: true,
      description: 'Price',
      validation: isValidAmount
    },
    'transaction-price-currency': {
      required: true,
      description: 'Currency code for the transaction price',
      validation: validateCurrency
    },
    'transaction-costs-currency': {
      required: false,
      description: 'Currency code for transaction costs',
      validation: validateCurrency
    },
    'transaction-tax-currency': {
      required: false,
      description: 'Currency code for transaction tax',
      validation: validateCurrency
    },
    'transaction-price-exchange-rate': {
      required: false,
      description: 'Exchange rate',
      validation: isValidAmount,
      show: (context) => true
    },
    'transaction-price-exchange-rate-currency': {
      required: false,
      description: 'Currency code for price exchange rate',
      validation: validateCurrency,
      show: (context) => true
    },
    'transaction-costs-exchange-rate-currency': {
      required: false,
      description: 'Currency code for costs exchange rate',
      validation: validateCurrency
    },
    'transaction-tax-exchange-rate-currency': {
      required: false,
      description: 'Currency code for tax exchange rate',
      validation: validateCurrency
    }
  },

  // Dividend specific rules
  dividend: {
    name: {
      required: true,
      description: 'Security name'
    },
    type: {
      required: true,
      description: 'Security type',
      validation: (value) => VALID_SECURITY_TYPES.includes(value)
    },
    exchange: {
      required: true,
      description: 'Exchange'
    },
    'dividend-amount': {
      required: true,
      description: 'Amount',
      validation: isValidAmount
    },
    'dividend-amount-currency': {
      required: false,
      description: 'Currency of the dividend amount',
      validation: validateCurrency,
      show(context) {
        return context['dividend-type'] === VALID_DIVIDEND_TYPES[0];
      }
    },
    'dividend-amount-exchange-rate': {
      required: false,
      description: 'Exchange rate of the dividend amount',
      validation: isValidAmount,
      show(context) {
        return context['dividend-type'] === VALID_DIVIDEND_TYPES[0];
      }
    },
    'dividend-amount-exchange-rate-currency': {
      required: false,
      description: 'Currency of the dividend amount exchange rate',
      validation: validateCurrency,
      show(context) {
        return context['dividend-type'] === VALID_DIVIDEND_TYPES[0];
      }
    },
    'dividend-type': {
      required: true,
      description: 'Type of dividend (Cash, Stock, or Staking)',
      validation: (value) => VALID_DIVIDEND_TYPES.includes(value)
    }
  },

  // Booking specific rules
  booking: {
    'booking-action': {
      required: true,
      description: 'Deposit/Withdrawal',
      validation: (value) => ['Deposit', 'Withdrawal'].includes(value)
    },
    'booking-amount': {
      required: true,
      description: 'Amount',
      validation: isValidAmount
    },
    'booking-amount-currency': {
      required: true,
      description: 'Currency'
    }
  },

  // Expense specific rules
  expense: {
    description: {
      required: true,
      description: 'Description'
    },
    'expense-amount': {
      required: true,
      description: 'Amount',
      validation: isValidAmount
    },
    'expense-amount-currency': {
      required: true,
      description: 'Currency'
    },
    'expense-amount-exchange-rate': {
      required: false,
      description: 'Exchange rate',
      validation: isValidAmount,
      show: (context) => !!context['expense-amount-currency']
    },
    'expense-amount-exchange-rate-currency': {
      required: false,
      description: 'Currency code for expense exchange rate',
      validation: validateCurrency,
      show: (context) => !!context['expense-amount-exchange-rate']
    }
  }
};

// Helper function to determine security type
export const determineSecurityType = (assetName = '', assetCategory = '') => {
  // If assetCategory is an ISIN, it must be Stock market
  if (isValidIsin(assetCategory)) {
    return 'Stock market';
  }

  const lowerName = assetName.toLowerCase();
  const lowerCategory = assetCategory.toLowerCase();

  if (lowerCategory.includes('fund') || lowerName.includes('etf')) {
    return 'Funds';
  }

  // Check for crypto and extract symbol
  if (lowerCategory.includes('crypto') || lowerName.includes('bitcoin') || lowerName.includes('ethereum')) {
    // Extract crypto symbol (e.g., BTC, ETH) from the name if present
    const cryptoMatches = assetName.match(/[A-Z]{3,5}/);
    if (cryptoMatches) {
      return cryptoMatches[0];  // Return the crypto symbol
    }
    return 'Crypto';  // Default if no symbol found
  }

  // Check for commodities and validate against VALID_COMMODITIES
  if (lowerCategory.includes('commodit') || 
      VALID_COMMODITIES.some(metal => lowerName.includes(metal.toLowerCase()))) {
    // Find which commodity it is
    const commodity = VALID_COMMODITIES.find(metal => lowerName.includes(metal.toLowerCase()));
    if (commodity) {
      return commodity;  // Return the specific commodity name
    }
    return 'Commodity';  // Default if no specific commodity found
  }

  // Default to stock market for regular shares
  return 'Stock market';
};
