import BaseCsvPreprocessor from './BaseCsvPreprocessor.js';

class AbnAmroCsvPreprocessor extends BaseCsvPreprocessor {
    constructor() {
        super();
    }

    /**
     * Parse a CSV line that may contain quoted fields
     * @param {string} line - CSV line to parse
     * @returns {string[]} - Array of fields
     */
    parseCSVLine(line) {
        // Remove the outer quotes if they exist
        line = line.replace(/^"|"$/g, '');
        
        // Split by double quotes followed by comma
        const fields = line.split('","').map(field => {
            // Remove any remaining quotes and trim
            return field.replace(/"/g, '').trim();
        });
        
        return fields;
    }

    /**
     * Format a field by replacing commas with periods in numeric values
     * @param {string} value - Field value to format
     * @param {number} index - Field index
     * @returns {string} - Formatted value
     */
    formatField(value, index) {
        // Skip date field (index 0)
        if (index === 0) {
            return value;
        }
        
        // Handle special case for '--,--'
        if (value === '--,--') {
            return '0.00';
        }
        
        // If the field contains a comma and can be converted to a number, replace comma with period
        if (value.includes(',')) {
            // Remove thousand separators first
            const withoutThousands = value.replace(/\./g, '');
            const potentialNumber = withoutThousands.replace(',', '.');
            if (!isNaN(parseFloat(potentialNumber))) {
                return potentialNumber;
            }
        }
        
        return value;
    }

    /**
     * Preprocess ABN AMRO CSV content
     * @param {string} csvContent - Raw CSV content
     * @returns {string} - Preprocessed CSV content
     */
    preprocess(csvContent) {
        if (!this.validateFormat(csvContent)) {
            throw new Error('Invalid ABN AMRO CSV format');
        }

        const lines = csvContent.trim().split('\n');
        const header = lines[0]; // Keep header as is
        
        // Process all lines except header
        const processedLines = lines.slice(1).map(line => {
            if (!line.trim()) return ''; // Skip empty lines
            
            // Parse the CSV line properly
            const fields = this.parseCSVLine(line);
            
            // Process each field
            const processedFields = fields.map((field, index) => this.formatField(field, index));
            
            return processedFields.join(',');
        });

        return [header, ...processedLines.filter(line => line)].join('\n');
    }

    /**
     * Validate if the content matches ABN AMRO's CSV format
     * @param {string} csvContent - Raw CSV content
     * @returns {boolean} - True if the format is valid
     */
    validateFormat(csvContent) {
        if (!csvContent) return false;
        
        const lines = csvContent.trim().split('\n');
        if (lines.length < 2) return false;
        
        // Check header format
        const expectedHeader = 'Datum,Naam,Netto waarde,Aantal/Bedrag,Type,Koers,Order type';
        return lines[0].trim() === expectedHeader;
    }
}

export default AbnAmroCsvPreprocessor;
