// Global exchange mapping configuration
export const EXCHANGE_MAPPING = {
  // USA Markets
  'XNAS': 'Nasdaq',
  'NDQ': 'Nasdaq',
  'NASDAQ': 'Nasdaq',
  'NAS': 'Nasdaq',
  'NMS': 'Nasdaq',
  'NSQ': 'Nasdaq',
  
  'XNYS': 'New York Stock Exchange',
  'NSY': 'New York Stock Exchange',
  'NYSE': 'New York Stock Exchange',
  'NYS': 'New York Stock Exchange',
  
  'BATS': 'Bats',
  'USBATS': 'Bats',
  
  'ASE': 'NYSE American',
  'XASE': 'NYSE American',
  
  'PINX': 'OTC Markets',
  'OTCM': 'OTC Markets',

  // European Markets
  'LSE': 'London Stock Exchange',
  'XLON': 'London Stock Exchange',
  'LON': 'London Stock Exchange',
  
  'BE': 'Berlin Exchange',
  'XBER': 'Berlin Exchange',
  'BER': 'Berlin Exchange',
  
  'HM': 'Hamburg Exchange',
  'XHAM': 'Hamburg Exchange',
  'HAM': 'Hamburg Exchange',
  
  'XETRA': 'XETRA Exchange',
  'XETR': 'XETRA Exchange',
  'XET': 'XETRA Exchange',
  'ETR': 'XETRA Exchange',
  'GER': 'XETRA Exchange',
  
  'DU': 'Dusseldorf Exchange',
  'XDUS': 'Dusseldorf Exchange',
  'DUS': 'Dusseldorf Exchange',
  
  'HA': 'Hanover Exchange',
  'XHAN': 'Hanover Exchange',
  'HAN': 'Hanover Exchange',
  
  'MU': 'Munich Exchange',
  'XMUN': 'Munich Exchange',
  'MUN': 'Munich Exchange',
  
  'F': 'Frankfurt Exchange',
  'XFRA': 'Frankfurt Exchange',
  'FRA': 'Frankfurt Exchange',
  
  'STU': 'Stuttgart Exchange',
  'XSTU': 'Stuttgart Exchange',
  
  'LU': 'Luxembourg Stock Exchange',
  'XLUX': 'Luxembourg Stock Exchange',
  'LUX': 'Luxembourg Stock Exchange',
  
  'VI': 'Vienna Exchange',
  'XWBO': 'Vienna Exchange',
  'WEN': 'Vienna Exchange',
  'WBO': 'Vienna Exchange',
  
  'MI': 'Borsa Italiana',
  'XMIL': 'Borsa Italiana',
  'MIL': 'Borsa Italiana',
  
  'PA': 'Euronext Paris',
  'XPAR': 'Euronext Paris',
  'EPA': 'Euronext Paris',
  'PAR': 'Euronext Paris',
  'EMA': 'Euronext Paris',
  
  'BR': 'Euronext Brussels',
  'XBRU': 'Euronext Brussels',
  'EBR': 'Euronext Brussels',
  'BRU': 'Euronext Brussels',
  
  'AS': 'Euronext Amsterdam',
  'XAMS': 'Euronext Amsterdam',
  'EAM': 'Euronext Amsterdam',
  'AMS': 'Euronext Amsterdam',
  'AEX': 'Euronext Amsterdam',
  
  'LS': 'Euronext Lisbon',
  'XLIS': 'Euronext Lisbon',
  'LIS': 'Euronext Lisbon',
  
  'MC': 'Bolsa de Madrid',
  'BMEX': 'Bolsa de Madrid',
  'MAD': 'Bolsa de Madrid',
  
  'SW': 'SIX Swiss Exchange',
  'XSWX': 'SIX Swiss Exchange',
  'SWX': 'SIX Swiss Exchange',
  'VTX': 'SIX Swiss Exchange',
  'VX': 'SIX Swiss Exchange',
  
  'IR': 'Euronext Dublin',
  'XDUB': 'Euronext Dublin',
  'DUB': 'Euronext Dublin',

  // Nordic Markets
  'VS': 'Vilnus Exchange',
  'XLIT': 'Vilnus Exchange',
  'LIT': 'Vilnus Exchange',
  
  'NB': 'Nasdaq Baltic',
  'XTAL': 'Nasdaq Baltic',
  'TAL': 'Nasdaq Baltic',
  
  'RG': 'Riga Exchange',
  'XRIS': 'Riga Exchange',
  'RIS': 'Riga Exchange',
  
  'HE': 'Helsinki Exchange',
  'XHEL': 'Helsinki Exchange',
  'HSE': 'Helsinki Exchange',
  'HEL': 'Helsinki Exchange',
  
  'IC': 'Iceland Exchange',
  'XICE': 'Iceland Exchange',
  'ICE': 'Iceland Exchange',
  
  'OL': 'Oslo Stock Exchange',
  'XOSL': 'Oslo Stock Exchange',
  'OSL': 'Oslo Stock Exchange',
  
  'CO': 'Copenhagen Exchange',
  'XCSE': 'Copenhagen Exchange',
  'NFN': 'Copenhagen Exchange',
  'CSE': 'Copenhagen Exchange',
  
  'ST': 'Stockholm Exchange',
  'OMX': 'Stockholm Exchange',
  'XSTO': 'Stockholm Exchange',
  'OME': 'Stockholm Exchange',

  // Asian Markets
  'TA': 'Tel Aviv Exchange',
  'XTAE': 'Tel Aviv Exchange',
  'TAE': 'Tel Aviv Exchange',
  
  'HK': 'Hong Kong Exchange',
  'XHKG': 'Hong Kong Exchange',
  'HKS': 'Hong Kong Exchange',
  'HKG': 'Hong Kong Exchange',
  
  'KO': 'Korea Stock Exchange',
  'XKRX': 'Korea Stock Exchange',
  'KRX': 'Korea Stock Exchange',
  
  'KQ': 'KOSDAQ',
  'XKOS': 'KOSDAQ',
  'KRKSDQ': 'KOSDAQ',
  
  'WAR': 'Warsaw Stock Exchange',
  'XWAR': 'Warsaw Stock Exchange',
  
  'BUD': 'Budapest Stock Exchange',
  'XBUD': 'Budapest Stock Exchange',
  
  'PSE': 'Philippine Stock Exchange',
  'XPHS': 'Philippine Stock Exchange',
  'PHS': 'Philippine Stock Exchange',
  
  'SG': 'Singapore Exchange',
  'XSES': 'Singapore Exchange',
  'SES': 'Singapore Exchange',
  
  'BSE': 'Bombay Exchange',
  'XBOM': 'Bombay Exchange',
  'BOM': 'Bombay Exchange',
  
  'TSE': 'Tokyo Stock Exchange',
  'XJPX': 'Tokyo Stock Exchange',
  'JPTSE': 'Tokyo Stock Exchange',
  'XCA': 'Tokyo Stock Exchange',
  
  'KAR': 'Karachi Stock Exchange',
  'XKAR': 'Karachi Stock Exchange',
  
  'SHE': 'Shenzhen Exchange',
  'XSHE': 'Shenzhen Exchange',
  
  'SHG': 'Shanghai Exchange',
  'XSHG': 'Shanghai Exchange',
  
  'JK': 'Jakarta Exchange',
  'XIDX': 'Jakarta Exchange',
  'JKT': 'Jakarta Exchange',
  
  'NSE': 'NSE (India)',
  'XNSE': 'NSE (India)',
  'NSEI': 'NSE (India)',

  // Other Markets
  'TO': 'Toronto Exchange',
  'XTSE': 'Toronto Exchange',
  'TOR': 'Toronto Exchange',
  'TSE': 'Toronto Exchange',
  
  'ASX': 'Australian Securities Exchange',
  'XASX': 'Australian Securities Exchange',
  
  'JSE': 'Johannesburg Stock Exchange',
  'XJSE': 'Johannesburg Stock Exchange',
  
  'SAO': 'Sao Paulo Exchange',
  'BVMF': 'Sao Paulo Exchange',
  
  'MXK': 'Mexico Exchange',
  'XMEX': 'Mexico Exchange',
  'MEX': 'Mexico Exchange',
  
  'SAN': 'Santiago Exchange',
  'XSGO': 'Santiago Exchange',
  'SGO': 'Santiago Exchange',
  
  'BUE': 'Buenos Aires Exchange',
  'XBUE': 'Buenos Aires Exchange',
  
  'LIM': 'Lima Exchange',
  'XLIM': 'Lima Exchange',
  
  'CAS': 'Casablanca Exchange',
  'XCAS': 'Casablanca Exchange',
  
  // Crypto Markets
  'BITFINEX': 'Bitfinex',
  'BINANCE': 'Binance',
  'KRAKEN': 'Kraken',
  'COINBASE': 'Coinbase',
  'BITSTAMP': 'Bitstamp',
  'GEMINI': 'Gemini',
  'HUOBI': 'Huobi',
  'KUCOIN': 'KuCoin',
  'POLONIEX': 'Poloniex',
  'BITTREX': 'Bittrex'
};

/**
 * Maps an exchange code to its proper exchange name
 * @param {string} exchangeCode - The exchange code to map
 * @returns {string} The mapped exchange name or empty string if no mapping found
 */
export const mapExchange = (exchangeCode) => {
  if (!exchangeCode) return '';
  const code = exchangeCode.toUpperCase();
  return EXCHANGE_MAPPING[code] || '';
};
