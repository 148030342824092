import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';

export class AbnAmroExpenseConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Datum',
      nameField: 'Naam',
      amountField: 'Netto waarde',
      typeField: 'Type',
      orderTypeField: 'Order type',
      type: 'expenses',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField]) {
        return false;
      }

      const type = (row[this.options.typeField] || '').toLowerCase();
      return ['fee', 'interest', 'commission'].includes(type);
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = this.parseAmount(row[this.options.amountField] || '0');
      if (isNaN(amount) || amount === 0) {
        return null;
      }

      const type = (row[this.options.typeField] || '').toLowerCase();
      return {
        broker: this.selectedBroker,
        date: formatDate(row[this.options.dateField]),
        time: formatTime(row[this.options.timeField] || '00:00'),
        'expense-type': type,
        'expense-amount': formatAmount(Math.abs(amount)),
        'expense-currency': this.getValidCurrency(row.Currency || 'EUR'),
        'expense-exchange-rate': '',
        'expense-exchange-rate-currency': '',
        'expense-description': row[this.options.nameField] || type
      };
    }).filter(row => row !== null);
  }
}
