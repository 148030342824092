import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';
import { mapExchange } from '../../../config/exchangeMapping';
import { VALID_SECURITY_TYPES } from '../../../config/constants';
import { VALID_DIVIDEND_TYPES } from '../../../config/constants';

export class AbnAmroDividendConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Datum',
      nameField: 'Naam',
      amountField: 'Aantal/Bedrag',
      netValueField: 'Netto waarde',
      typeField: 'Type',
      orderTypeField: 'Order type',
      type: 'dividends',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  /**
   * Determines the dividend type based on the transaction details
   * @param {Object} row The transaction row
   * @returns {string} One of the valid dividend types: 'Cash', 'Stock', or 'Staking'
   */
  determineDividendType(row) {
    const amount = this.parseAmount(row[this.options.amountField] || '0');
    const netValue = this.parseAmount(row[this.options.netValueField] || '0');
    const type = (row[this.options.typeField] || '').toLowerCase();
    const name = (row[this.options.nameField] || '').toLowerCase();
    
    // Check if it's explicitly a stock dividend
    if (type.includes('stockdiv') || type.includes('stock div') || name.includes('stockdiv') || name.includes('stock div')) {
      return VALID_DIVIDEND_TYPES[1]; // Stock dividend
    }
    
    // If there's a cash amount, it's a cash dividend
    if (netValue > 0) {
      return VALID_DIVIDEND_TYPES[0]; // Cash dividend
    }
    
    // Default to cash dividend
    return VALID_DIVIDEND_TYPES[0];
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField] || !row[this.options.nameField]) {
        return false;
      }

      const orderType = (row[this.options.orderTypeField] || '').toLowerCase();
      const netValue = this.parseAmount(row[this.options.netValueField] || '0');
      const amount = this.parseAmount(row[this.options.amountField] || '0');
      const type = (row[this.options.typeField] || '').toLowerCase();
      const name = (row[this.options.nameField] || '').toLowerCase();
      
      // Include CA transactions that are dividends:
      // 1. Explicit stock dividends in type or name
      // 2. CA transactions with positive net value (indicating dividend payment)
      // 3. CA transactions with share amounts (indicating stock dividend)
      return orderType === 'ca' && (
        type.includes('stockdiv') || 
        type.includes('stock div') || 
        name.includes('stockdiv') || 
        name.includes('stock div') ||
        netValue > 0 ||
        amount > 0
      );
    });
  }

  isDividendTransaction(row) {
    const type = (row[this.options.typeField] || '').toLowerCase();
    const name = (row[this.options.nameField] || '').toLowerCase();
    const orderType = (row[this.options.orderTypeField] || '').toLowerCase();

    // Check for dividend keywords in type or name
    return type.includes('dividend') || 
           name.includes('dividend') ||
           name.includes('div') ||
           (orderType === 'ca' && this.parseAmount(row[this.options.netValueField] || '0') > 0);
  }

  transformData(data) {
    return data.map(row => {
      const amount = Math.abs(this.parseAmount(row[this.options.netValueField] || '0'));
      const shares = Math.abs(this.parseAmount(row[this.options.amountField] || '0'));
      const type = this.determineDividendType(row);
      const isStockDividend = type === VALID_DIVIDEND_TYPES[1];
      
      return {
        broker: this.selectedBroker,
        name: row[this.options.nameField] || '',
        type: VALID_SECURITY_TYPES[0],
        search: '',
        exchange: '',
        date: formatDate(row[this.options.dateField]),
        time: formatTime(row[this.options.timeField] || '00:00'),
        'dividend-type': type,
        'dividend-amount': isStockDividend ? '' : formatAmount(amount),
        'dividend-amount-currency': this.getValidCurrency(row.Currency || 'EUR'),
        'dividend-amount-exchange-rate': '',
        'dividend-amount-exchange-rate-currency': '',
        'dividend-tax': '',
        'dividend-tax-currency': '',
        'dividend-tax-exchange-rate': '',
        'dividend-tax-exchange-rate-currency': ''
      };
    }).filter(row => row !== null);
  }
}
