import { BaseConverter } from '../../../core/BaseConverter';
import { formatAmount, formatDate, formatTime } from '../../../utils/formatters';

export class AbnAmroBookingConverter extends BaseConverter {
  constructor(csvContent, selectedBroker, options = {}) {
    const defaultOptions = {
      dateField: 'Datum',
      nameField: 'Naam',
      amountField: 'Netto waarde',
      typeField: 'Type',
      orderTypeField: 'Order type',
      timeField: 'Tijd',
      type: 'bookings',
      ...options
    };
    super(csvContent, selectedBroker, defaultOptions);
  }

  filterData(data) {
    if (!Array.isArray(data)) {
      return [];
    }

    return data.filter(row => {
      if (!row[this.options.dateField]) {
        return false;
      }

      const type = (row[this.options.typeField] || '').toLowerCase();
      return ['deposit', 'withdrawal'].includes(type);
    });
  }

  transformData(data) {
    return data.map(row => {
      const amount = this.parseAmount(row[this.options.amountField] || '0');
      if (isNaN(amount) || amount === 0) {
        return null;
      }

      const type = (row[this.options.typeField] || '').toLowerCase();
      const isDeposit = type === 'deposit';
      return {
        broker: this.selectedBroker,
        date: formatDate(row[this.options.dateField]),
        time: formatTime(row[this.options.timeField] || '00:00'),
        'booking-action': this.getValidBookingAction(isDeposit),
        'booking-amount': formatAmount(Math.abs(amount)),
        'booking-currency': this.getValidCurrency(row.Currency || 'EUR'),
        'booking-description': row[this.options.nameField] || ''
      };
    }).filter(row => row !== null);
  }
}
